import {
  AvvNodeGrammar,
  DeltaParser,
  HtmlParser,
  isInsert,
  type MountedEditor,
} from '@avvoka/editor'
import {
  fromHtmlEntities,
  getObjectEntries,
  toHtmlEntities
} from '@avvoka/shared'

// Returns true if editor contains text
export function hasText (editor: MountedEditor) {
  return editor.getDelta().ops.some((op) => isInsert(op) && op.insert && op.insert !== '\n')
}

export function appendFootnotes(editorHtml: string, editor: MountedEditor) {
  const bridge = EditorFactory.getEntry(editor.options.id!).get().bridge!
  editorHtml += '<avv-footnotes>'
  getObjectEntries(bridge.footnotes).forEach(([key, value]) => {
    editorHtml += `<avv-footnote data-id="${key}">${value}</avv-footnote>`
  })
  editorHtml += '</avv-footnotes>'
  return editorHtml
}

export function deleteFootnotes(editorHtml: string, editor: MountedEditor) {
  const bridge = EditorFactory.getEntry(editor.options.id!).get().bridge!
  // Extract footnotes and remove them from the editorHtml
  let footnotesMatch
  while (
    (footnotesMatch = /<avv-footnotes>(?:.|\n)*?<\/avv-footnotes>/g.exec(
      editorHtml
    )) !== null
  ) {
    editorHtml = editorHtml.replace(footnotesMatch[0], '')

    // Extract each footnote and set it to entry.bridge.footnotes
    const footnoteRegex =
      /<avv-footnote data-id="([^"]*?)">([\s\S]*?)<\/avv-footnote>/g
    let match
    while ((match = footnoteRegex.exec(footnotesMatch[0])) !== null) {
      if (match.index === footnoteRegex.lastIndex) {
        footnoteRegex.lastIndex++
      }

      const id = match[1]
      const value = match[2]
      bridge.footnotes[id] = value
    }
  }
  return editorHtml
}

export const unsanitisedHtmlNode = (html: string) => {
  const node = HtmlParser.parse(html)

  for (const child of node[Symbol.iterator](true, true)) {
    if (child.isText()) {
      child.data = fromHtmlEntities(child.data)
    }

    if (child.isElement()) {
      getObjectEntries(child.attributes).forEach(([key, value]) => {
        child.attributes[key] = value
          .replace(/_Avvspace_aVV/g, ' ')
          .replace(/_Avvdot_aVV/g, '.')
      })
    }
  }
  return node
}

export const deltaToSanitisedHtml = (
  editor: MountedEditor,
  appendFootnotesAtEnd = true
) => {
  const node = DeltaParser.parse(editor.getDelta())

  const docxHtmlEntities = {
    60: '&lt;',
    62: '&gt;',
    34: '&quot;',
    38: '&amp;'
  }

  for (const child of node[Symbol.iterator](true, true)) {
    if (child.isText()) {
      child.data = toHtmlEntities(child.data, docxHtmlEntities, false)
    }

    if (child.isElement()) {
      getObjectEntries(child.attributes).forEach(([key, value]) => {
        if (typeof child.attributes[key] === 'string') {
          child.attributes[key] = value
            .replace(/ /g, '_Avvspace_aVV')
            .replace(/\./g, '_Avvdot_aVV')
        }
      })
    }
  }

  AvvNodeGrammar.applyInlineNormalization(node, editor.options.mode)

  let html = node.toHTML(false, undefined, false)
  if (appendFootnotesAtEnd) {
    html = appendFootnotes(html, editor)
  }

  return html
}
